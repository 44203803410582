import { ChainId } from '@pancakeswap/chains'
import {
  BIT_QUERY,
  INFO_CLIENT,
  STABLESWAP_SUBGRAPH_CLIENT,
  INFO_CLIENT_ETH,
  V3_SUBGRAPH_URLS,
  V2_SUBGRAPH_URLS,
  ORDERS_CLIENT_WITH_CHAIN,
  STATS_CLIENT
} from 'config/constants/endpoints'
import { GraphQLClient } from 'graphql-request'

// Extra headers
// Mostly for dev environment
// No production env check since production preview might also need them
export const getGQLHeaders = (endpoint: string) => {
  if (endpoint === INFO_CLIENT && process.env.NEXT_PUBLIC_NODE_REAL_HEADER) {
    return {
      origin: process.env.NEXT_PUBLIC_NODE_REAL_HEADER,
    }
  }
  return undefined
}

export const infoClient = new GraphQLClient(INFO_CLIENT)

export const infoClientWithChain = (chainId: number) => {
  if (V2_SUBGRAPH_URLS[chainId]) {
    return new GraphQLClient(V2_SUBGRAPH_URLS[chainId], {
      headers: getGQLHeaders(V2_SUBGRAPH_URLS[chainId]),
    })
  }
  return undefined
}

export const ordersClientWithChain = (chainId: number) => {
  return new GraphQLClient(ORDERS_CLIENT_WITH_CHAIN[chainId])
}

export const statsClient = new GraphQLClient(STATS_CLIENT)

export const v3Clients = {
  [ChainId.ETHEREUM]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.ETHEREUM]),
  [ChainId.PULSE]: new GraphQLClient(V3_SUBGRAPH_URLS[ChainId.PULSE]),
}

export const v2Clients = {
  [ChainId.ETHEREUM]: new GraphQLClient(V2_SUBGRAPH_URLS[ChainId.ETHEREUM]),
  [ChainId.PULSE]: new GraphQLClient(V2_SUBGRAPH_URLS[ChainId.PULSE]),
}

export const stableClient = new GraphQLClient(STABLESWAP_SUBGRAPH_CLIENT)

export const bitQueryServerClient = new GraphQLClient(BIT_QUERY, {
  headers: {
    // only server, no `NEXT_PUBLIC` not going to expose in client
    'X-API-KEY': process.env.BIT_QUERY_HEADER || '',
  },
  timeout: 5000,
})
